/* src/components/footer/Footer.module.css */
.footer {
    color: #34495f;
    padding: 1rem;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    font-size: 13px;
    font-family: poppins;
    text-align: left;
  }