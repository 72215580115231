/* src/components/layout/Layout.module.css */
.main_content {
    padding: 1rem;
    padding-bottom: 60px;
    background-color: #f5f5f5;
  }
  
  .full_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  