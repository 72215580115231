.carousel-inner {
    width: 100%;
    height: 65vh;
    background-color: #c0defa;
    
}
.navbar-nav li:hover>ul.dropdown-menu{
    display: block;
} 
.dropdown-submenu{
    position: relative;
    
}
.dropdown-submenu>.dropdown-menu{
    top:0;
    margin-top: -5px;
    left: 100%;
    
}
.dropdown-menu>li>a:hover::after{
    text-decoration: underline;
    transform: rotate(-90deg);
    
}
#fontfamily{
    color: #6d7c85;
    font-size: larger;
    font-family: 'Poppins'; 
}

